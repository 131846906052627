@font-face{
    font-family: Averta;
    font-style: normal;
    font-weight: 300;
    src: local("Averta"),url('./fonts/light.woff2') format("truetype")
}

@font-face{
    font-family: Averta;
    font-style: normal;
    font-weight: 400;
    src: local("Averta"),url('./fonts/regular.woff2') format("truetype")
}

@font-face{
    font-family: Averta;
    font-style: normal;
    font-weight: 500;
    src: local("Averta"),url('./fonts/bold.woff2') format("truetype")
}

@font-face{
    font-family: Averta;
    font-style: normal;
    font-weight: 600;
    src: local("Averta"),url('./fonts/semibold.woff2') format("truetype")
}

@font-face{
    font-family: Averta;
    font-style: normal;
    font-weight: 700;
    src: local("Averta"),url('./fonts/extra-bold.woff2') format("truetype")
}

html, body, #root{
    height: 100%;
    color: #171619;
}

body{
    font-family: "Averta", "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

.mask{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background-color: rgba(0, 0, 0, .4);
}

.side-modal-body{
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: #fff;
	max-width: 100%;
	width: 60%;
	margin: 0 auto;
	padding: 44px;
	z-index: 11;
	box-sizing: border-box;
    overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.side-modal-body h2{
	font-size: 2.2rem !important;
}

#get-usage-option-wrapper{
	display: flex;
	flex-direction: column;
	margin-top: 12px;
}

.get-usage-option{
	cursor: pointer;
	border: 1px solid #ddd;
	border-radius: 16px;
	flex: 1;
	padding: 24px 20px;
	margin: 8px 0;
	display: flex;
	align-items: center;
	transition: border .3s;
	position: relative;
}

.get-usage-option img{
    margin-right: 20px;
}

.get-usage-option h5{
	font-size: 17px;
    margin: 0;
}

.get-usage-option:hover{
	border-color: #999;
}

.get-usage-option p{
    margin: 4px 0 0;
	font-size: 15px;
	line-height: 1.2;
}

.get-usage-option i{
	display: inline-block;
	font-style: normal;
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 20px;
	padding: 1px 8px;
	font-size: 13px;
	font-weight: bold;
	background: #5421b7;
	color: #fff;
}

#agreement-wrapper{
	border: 1px solid #ddd;
	padding: 4px 24px;
    max-height: 700px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

#agreement-wrapper table,#agreement-wrapper td{
	position: static;
}

@media (max-width: 750px) {
	.side-modal-body{
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
}